import { requestOfCallCancel } from '@!/utils/useCancelRequest'
import { useCommonStore, useShareStore } from '@!/store'
import { getShareConfig } from '@commerceCommon/utils/ts-common'
import { $http3p } from '../http'
const { VITE_APP_DATA_1P, VITE_APP_DATA_3P } = import.meta.env
const getCommerceType = () => {
  const { isShareLink, shareId } = getShareConfig()
  if (isShareLink) {
    const { channelType } = useShareStore().shareStates[shareId]
    return channelType
  }
  return useCommonStore().commerceType
}

// import { requestOfCallCancel } from '../http'

/**
 * @template T
 * @typedef { Promise<T> } AxiosLike<T>
 */

/**
 * Sales cards 当天卡片
 */
export function getSalesDetailToHour(data) {
  return $http3p({
    url: 'home/getSalesWidget',
    method: 'post',
    data
  })
}

/**
 * Traffic cards 卡片
 */
export function getTrafficDetail(data) {
  return $http3p({
    url: 'home/getTrafficWidget',
    method: 'post',
    data
  })
}

/**
 * Advertising cards 卡片
 */
export function getAdvertisingDetail(data) {
  return $http3p({
    url: 'home/getAdvertisingDetail',
    method: 'post',
    data
  })
}

/**
 * Sales Trend 趋势图
 * @returns { AxiosLike<{cancelledOrders: string[], orders: string[], sales: string[], salesUnits: string[], xList: string[], updateTime: string}> } res
 */
export function getSalesTrend(data) {
  return requestOfCallCancel({
    url: 'salesTrend/getInfo',
    _tag: getSalesTrend.name,
    method: 'post',
    data
  })
}

/**
 * Sales Trend 趋势图下载
 */
export function downloadSalesTrend(data) {
  return requestOfCallCancel({
    url: '/salesTrend/downloadSalesTrend',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 * Advertising Trend 趋势图
 * @returns { AxiosLike<{adSalesList: string[], adSpendList: string[], tAcosList: string[], xList: string[]}> } res
 */
export function getAdvertisingTrend(data) {
  return requestOfCallCancel({
    url: 'advertisingTrend/getInfo',
    method: 'post',
    data
  })
}

/**
 * Advertising Trend 趋势图下载
 */
export function downloadAdvertisingTrend(data) {
  return requestOfCallCancel({
    url: '/advertisingTrend/downloadAdvertisingTrend',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

// 获取 Ad Supported sales performance chart 数据
export const getAdSalesPerformance = (data) => {
  return requestOfCallCancel({
    url: '/home/getAdSalesPerformance',
    _tag: getAdSalesPerformance.name,
    method: 'post',
    data
  })
}

/**
 * Ad Supported sales performance chart 趋势图下载
 */
export function downloadAdSalesPerformance(data) {
  return requestOfCallCancel({
    url: '/home/downloadAdSalesPerformance',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}
/**
 * Top 10 图表数据
 * @typedef { unitsValue: string, sessionValue: string, salesValue: string, paramsValue: string, param: string, pageViewValue: string, detailList: string[] } TopTenInfoType
 * @returns { AxiosLike<{infoList: TopTenInfoType[], xList: string[]}> } res
 */
export function getHomeTopTenInfo(data) {
  return requestOfCallCancel({
    url: 'homeTop/topTenInfo',
    _tag: getHomeTopTenInfo.name,
    method: 'post',
    data: {
      ...data,
      source: 'home3p'
    }
  })
}

/**
 * Top 10 图表数据下载
 */
export function downloadHomeTopTen(data) {
  return requestOfCallCancel({
    url: '/homeTop/downloadTopTen',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 * Hourly Orders 图表数据
 */
export function getHourlyOrdersData(data) {
  return requestOfCallCancel({
    url: 'hourlyOrders/getHourlyOrdersData',
    method: 'post',
    data
  })
}

/**
 * Hourly Orders 图表数据下载
 */
export function downloadHourlyOrdersData(data) {
  return requestOfCallCancel({
    url: '/hourlyOrders/downloadHourlyOrdersData',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 *  Account Health
 */
export function getAccountHealthData(data) {
  return requestOfCallCancel({
    url: 'accountHealth/getAccountHealthData',
    _tag: getAccountHealthData.name,
    method: 'post',
    data
  })
}

/**
 * Account Health - See All
 */
export function getAccountHealthDataDetail(data) {
  return requestOfCallCancel({
    url: 'accountHealth/getAccountHealthDataDetail',
    method: 'post',
    data
  })
}

/**
 * Inventory Age Chart
 * @returns { Promise<{ age0To90DayUnits: number, age91To180DayUnits: number, age181To270DayUnits: number, age271To365DayUnits: number, age365plusDayUnits: number, totalUnits: number }> }
 */
export function getInventoryAge(data) {
  return requestOfCallCancel({
    url: 'home/inventoryAge',
    method: 'post',
    data
  })
}

/**
 * Inventory Status Chart
 * @returns { Promise<{ damageUnits: number, defectiveUnits: number, expiredUnits: number, sellableUnits: number, totalUnits: number }> }
 */
export function getInventoryStatusDistribution(data) {
  return requestOfCallCancel({
    url: 'home/inventoryStatusDistribution',
    method: 'post',
    data
  })
}

/**
 * Replenishment Advice 表格数据
 */
export function getReplenishmentAdviceDataPage(data) {
  return requestOfCallCancel({
    url: 'replenishmentAdvice/getReplenishmentAdviceDataPage',
    method: 'post',
    data
  })
}

/**
 * Replenishment Advice 表格数据下载
 */
export function downloadReplenishmentAdvice(data) {
  return requestOfCallCancel({
    url: '/replenishmentAdvice/downloadReplenishmentAdvice',
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 * Order Distribution Map Chart
 */
export function getMapOrdersAndReturnsDetail(data) {
  return requestOfCallCancel({
    url: 'HomeMap/mapOrdersAndReturnsDetail',
    method: 'post',
    data
  })
}

/**
 * Order Distribution Map Chart - 表格数据下载
 * @param {{
 *  countryName: string,
 *  sellerIds: string[],
 *  countryCodes: string[],
 *  startDate: string,
 *  endDate: string,
 *  downloadType: 'order' | 'return'
 *  shareLinkCurrency?: string
 *  shareId?: string
 * }} data
 * @returns
 */
export const exportDashboardMapApi = (data) => {
  return requestOfCallCancel({
    url: `HomeMap/downloadHomeMap`,
    method: 'post',
    data,
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 * TopTenMovers
 */
export function getTopTenMovers(data, type) {
  const baseURL = type === 'Vendor' ? VITE_APP_DATA_1P : VITE_APP_DATA_3P
  const url = type === 'Vendor' ? 'sales/getTopTenMovers' : 'salesTrend/getTopTenMovers'
  return requestOfCallCancel({
    baseURL,
    url,
    method: 'post',
    data
  })
}

/**
 * TopTenMovers 图表数据下载
 */
export function downloadTopTenMovers(data) {
  const commerceType = getCommerceType()
  const baseURL = commerceType === 'Vendor' ? VITE_APP_DATA_1P : VITE_APP_DATA_3P
  const url = commerceType === 'Vendor' ? '/sales/downloadTopTenMovers' : '/salesTrend/downloadTopTenMovers'
  return requestOfCallCancel({
    baseURL,
    url,
    data,
    method: 'post',
    requestRegisterType: 'global',
    fullData: true
  })
}

/**
 * Home 下载全部
 */
export function allDownloads(data) {
  return requestOfCallCancel({
    url: '/home/allDownloads',
    data,
    method: 'post',
    fullData: true
  })
}

/**
 * 自定义布局获取
 */
export function getCustomLayerConfig() {
  return requestOfCallCancel({
    url: '/home/getHomeComponentInfo',
    method: 'get',
    timeout: 1000 * 3, // home布局最多三秒
    noErrorTip: true, // 错误提示取消
    headers: {
      'platform-type': 3
    }
  })
}

/**
 * 自定义布局更新
 */
export function updateCustomLayerConfig(data) {
  return requestOfCallCancel({
    url: '/home/addOrUpdateHomeComponent',
    method: 'post',
    headers: {
      'platform-type': 3
    },
    data: {
      ...data,
      // ruleType 3是3P Home
      ruleType: 3
    }
  })
}
