import { dayjs } from '@pacvue/element-plus'

const watchFields = ['defaultReport']

export default {
  state: () => {
    return {
      platformName: 'commerce',
      defaultReport: {
        market: [],
        vendor: [],
        asin: [],
        searchKeyCondition: '0',
        dateRange: dayjs().subtract(1, 'months').startOf('month').toDate(),
        dateType: 'month',
        type: 'current',
        orderBy: '',
        orderByType: '',
        pageNum: 1,
        pageSize: 50,
        channel: '',
        quarterValue: dayjs().subtract(1, 'Q').startOf('Q').format('YYYY-MM-DD')
      }
    }
  },
  getters: {
  },
  actions: {
    setUserPersist() {
      const userid = localStorage.getItem('uid')
      watchFields.forEach((fieldName) => {
        const storeKey = userid + '_' + fieldName
        const storeStr = localStorage.getItem(storeKey)
        if (storeStr) {
          this[fieldName] = JSON.parse(storeStr)
        }
      })
    },
    setDefaultReport(payload) {
      this.defaultReport = { ...this.defaultReport, ...payload }
    }
  },
  // 开启数据缓存
  persist: {
    enabled: true,
    watchFields
  }
}
