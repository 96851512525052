import { CompressedPrefix, CompressFieldList, WatchFields } from './const.js'
import state from './state'
import getters from './getters'
import actions from './actions'
import { compress, decompress } from '../util'

export default {
  state,
  getters,
  actions,
  persist: {
    enabled: true,
    isCommon: true,
    watchFields: WatchFields,
    compressKeyList: CompressFieldList,
    CompressedPrefix,
    compress,
    decompress
  }
}
