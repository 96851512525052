import { SnSIdsApi, SnSIdsApi3p } from '@!/api/Account/SnS'
import { GetUserPermissionApi } from '@!/api/Account/user.js'
import { GetProductTagSellerDropDataByUserId, GetTagOwnerList } from '@!/api/Catalog/tag'
import * as commonApi from '@!/api/common/common'
import { GetFinancesFormulaCheckedApi, isManagedByPacvue } from '@!/api/common/common'
import { hasSnsApi3p } from '@!/api/Overview/snsOverview'
import { hasSnsApi } from '@!/api/Sales/Vendor'
import { PiniaPersistList } from '@!/store/index.js'
import { DefaultTagData } from '@commerceCommon/const/Other'
import { firstUpperString, isEmpty } from '@commerceCommon/utils/common'
import useFrameStore from '@pacvue/frame/frameStore'
import { intersection, uniqBy, cloneDeep, orderBy } from 'lodash-es'
import { defineAsyncComponent, defineComponent, h } from 'vue'
import { getManualPermission } from '@!/api/NewClaimList/index.js'
import { getAsyncDataType, noVendorSellerUrl } from '@commerceCommon/const/AsyncDataType'
import { useAsinDetailStore } from '../index'
import { CompressedPrefix, CompressFieldList, WatchFields } from './const.js'
import { ManagedByPacvueServerStatusMenu } from '@/const/ManagedByPacvueServerStatusMenu'
import { decompress } from '../util'

const keyMap = {
  Vendor: {
    marketKey: 'name',
    storeKey: 'marketAccountList',
    idKey: 'vendorGroupId'
  },
  Seller: {
    storeKey: 'sellerList',
    idKey: 'sellerId',
    nameKey: 'sellerName',
    marketKey: 'countryCode'
  }
}

export default {
  setBrandCategoryValueMap(val, commerceType) {
    Object.assign(this.brandCategoryValueMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setOriginBrandCategoryValueMap(val, commerceType) {
    Object.assign(this.originBrandCategoryValueMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setCustomBrandCategoryValueMap(val, commerceType) {
    Object.assign(this.customBrandCategoryValueMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setDistributorAndSellingMap(val) {
    Object.assign(this.distributorAndSellingMap, val)
  },
  setTagValueMap(val, commerceType) {
    Object.assign(this.tagValueMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setTagOwnerValueMap(val, commerceType) {
    Object.assign(this.tagOwnerValueMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setProductTagStore(value, commerceType) {
    this.productTagStore[commerceType || this.commerceType] = value
  },
  setProblemTypeValueMap(val, commerceType) {
    this.problemTypeValueMap[(commerceType || this.commerceType).toLowerCase()] = val
  },
  setCustomBrandStore(val, commerceType) {
    this.customBrandStore[commerceType] = val
  },
  setCustomCategoryStore(val, commerceType) {
    this.customCategoryStore[commerceType] = val
  },
  SET_PLATFORM(value) {
    this.platform = value || 'amazon'
    localStorage.setItem('productline', value)
  },
  setRepSuggestionType(playload) {
    this.repSuggestionType = playload
  },
  setUserPersist() {
    // 退出重新登录需要获取缓存数据的可以写在这个列表
    const tempFields = WatchFields
    const userid = localStorage.getItem('uid')
    tempFields.forEach((fieldName) => {
      const storageKey = PiniaPersistList.includes(fieldName) ? 'storage_' : ''
      const storeKey = `${storageKey}${userid}_${fieldName}`
      const storeStr = localStorage.getItem(storeKey)
      if (storeStr) {
        let tempString = storeStr
        if (CompressFieldList.includes(fieldName) && storeStr.startsWith(CompressedPrefix)) {
          const compressedString = storeStr.slice(CompressedPrefix.length)
          tempString = decompress(compressedString)
        }
        this[fieldName] = JSON.parse(tempString)
      }
    })
  },
  /**
   * 之后会定义localStorage和sessionStorage，登出的时候清除{uid}_开头的
   * 后续不想登出清除的
   * 2023-07-06 localStorage已配合pacvue模块清除，pacvue模块未清除的，可在此处处理
   */
  logout() {
    // window.localStorage.clear()
    // window.sessionStorage.clear()
    // const currentLocalStorage = window.localStorage
    // const uid = currentLocalStorage.getItem('uid')
    // Object.keys(currentLocalStorage).forEach(v => {
    //   const prefix = `${uid}_`
    //   if (v.startsWith(prefix) && !PiniaPersistList.includes(v.slice(prefix.length))) {
    //     currentLocalStorage.removeItem(v)
    //   }
    // })
    if (window.commerceWebSocket) {
      window.commerceWebSocket.close(1000, 'client close')
    }
    localStorage.removeItem('Menu')
    localStorage.removeItem('MenuMap')
    this.clearDownloadTaskIdList()
    // 清除AsinDetailStore
    useAsinDetailStore().clearAsinDetailStore()
  },
  getProFunctionGenerator(type, designatedCommerceType, convertDataFn, params, formData = {}, httpExtra = {}) {
    return new Promise((resolve, reject) => {
      const commerceType = this.commerceType
      const EXCLUDED_TYPES = new Set(getAsyncDataType)
      const NOVENDORSELERRURL = new Set(noVendorSellerUrl)
      let cacheCommerceType = designatedCommerceType || commerceType
      if (NOVENDORSELERRURL.has(type)) {
        designatedCommerceType = 'Seller'
        cacheCommerceType = commerceType
        if (params?.source) {
          cacheCommerceType = params?.source === 1 ? 'Vendor' : 'Seller'
        }
      } else {
        designatedCommerceType = designatedCommerceType || commerceType
      }
      if (EXCLUDED_TYPES.has(type)) {
        const brandCategoryStores = formData?.store || this.marketStoreValueMap[cacheCommerceType.toLowerCase()].store
        const brandCategoryMarkets = formData?.market || this.marketStoreValueMap[cacheCommerceType.toLowerCase()].market
        params = {
          ...params,
          brandCategoryStores,
          brandCategoryMarkets
        }
      } else {
        const cacheData = this[`${type}Store`][cacheCommerceType] || []
        if (cacheData.length && !params) {
          resolve(cacheData)
          return
        }
      }

      commonApi[`Get${firstUpperString(type)}${designatedCommerceType}DropData`](params || {}, httpExtra || {})
        .then((data) => {
          data = data || []
          if (convertDataFn) {
            data = convertDataFn(data)
          }
          if (NOVENDORSELERRURL.has(type)) {
            if (!params?.level || params?.level === 1) {
              // this[`${type}Store`][cacheCommerceType] = data
              /**
               * 不清楚为什么需要缓存
               */
              // this[`set${firstUpperString(type)}Store`](cloneDeep(data), cacheCommerceType)
            }
          } else {
            this[`${type}Store`][cacheCommerceType] = data
          }
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  /**
   * @param designatedCommerceType 指定的commerceType
   */
  getMarketStorePro(designatedCommerceType) {
    return this.getProFunctionGenerator('market', designatedCommerceType, (data) => {
      const commerceType = designatedCommerceType || this.commerceType
      const currentMap = keyMap[commerceType]
      return data.map(v => {
        return {
          market: v[currentMap.marketKey || 'market'],
          storeList: (v[currentMap.storeKey] || []).map(v => {
            return {
              ...v,
              name: v[currentMap.nameKey || 'name'],
              id: v[currentMap.idKey]
            }
          })
        }
      })
    })
  },
  // 同步获取 market DropData  根据 marketStore
  getMarketDropDataOfMarketStore(marketStoreData = this.marketStore[ this.commerceType ], keyConfig) {
    const defaultKeyConfig = {
      label: 'name',
      value: 'id'
    }
    if (keyConfig && typeof keyConfig === 'object') {
      Object.assign(defaultKeyConfig, keyConfig)
    }
    const marketDropData = []
    if (marketStoreData?.length) {
      marketStoreData.forEach((item) => {
        marketDropData.push({
          ...item,
          [defaultKeyConfig.label]: item.market,
          [defaultKeyConfig.value]: item.market
        })
      })
    }
    return uniqBy(marketDropData, 'id')
  },
  // 获取 store DropData 根据 market
  getStoreDropDataOfMarket(marketVal = [], designatedCommerceType, mergeConfig) {
    const marketDropData = this.marketStore[ designatedCommerceType || this.commerceType ]
    const defaultConfig = {
      // 如果 market 没有值 是否获取所有的market的 store
      isMarketEmptyEqAll: true,
      label: 'market',
      value: 'market'
    }
    if (mergeConfig && typeof mergeConfig === 'object') {
      Object.assign(defaultConfig, mergeConfig)
    }
    const storeDropData = []
    // market 下拉数据有值的情况
    if (marketDropData?.length) {
      // market val有值的情况
      if (marketVal?.length) {
        marketVal.forEach(valItem => {
          const checkedMarketStoreItem = marketDropData.find(item => {
            return item[defaultConfig.value] === valItem
          })
          if (checkedMarketStoreItem) {
            storeDropData.push(...checkedMarketStoreItem.storeList)
          }
        })
      } else {
        // market 全为空的情况
        if (defaultConfig.isMarketEmptyEqAll) {
          marketDropData.forEach(item => {
            storeDropData.push(...item.storeList)
          })
        }
      }
    }
    return uniqBy(orderBy(storeDropData, (item) => {
      return item.name.toLocaleLowerCase()
    }, ['asc']), 'id')
  },
  // 获取 Market DropData
  getMarketDropDataPro(designatedCommerceType) {
    return new Promise((resolve) => {
      this.getMarketStorePro(designatedCommerceType)
        .then(res => {
          const marketDropData = this.getMarketDropDataOfMarketStore(res)
          resolve(marketDropData)
        }).catch((err) => {
          console.error('getMarketDropData error: ' + err)
          resolve([])
        })
    })
  },
  // 获取 Store DropData With All
  getStoreDropDataPro(designatedCommerceType, marketVal = [...this.marketStoreValue.market]) {
    return new Promise((resolve) => {
      this.getMarketDropDataPro(designatedCommerceType)
        .then(() => {
          const storeAllDropData = this.getStoreDropDataOfMarket(marketVal, designatedCommerceType)
          resolve(storeAllDropData)
        }).catch((err) => {
          console.error('getStoreDropDataPro error: ' + err)
          resolve([])
        })
    })
  },
  // 同步获取 VendorCode DropData 关联 marketVal storeVal
  // 1. getVendorCodeDropData(storeVal , marketVal) 通过marketVal确认 storeDropData
  // 2. getVendorCodeDropData(storeVal , null, storeDropDataCustom) 自定义 storeDropData
  getVendorCodeDropData(
    storeVal = [],
    marketVal = [],
    storeDropDataCustom = []
  ) {
    const vendorCodeDropData = []
    let storeDropData
    if (Array.isArray(marketVal)) {
      storeDropData = [...this.getStoreDropDataOfMarket(marketVal, 'Vendor')]
    } else {
      storeDropData = [...storeDropDataCustom]
    }
    storeDropData.forEach(storeDropDataItem => {
      let hasVendorCodeDropDataItem
      if (storeVal.length === 0) {
        hasVendorCodeDropDataItem = true
      } else {
        hasVendorCodeDropDataItem = storeVal.includes(storeDropDataItem.id)
      }
      if (hasVendorCodeDropDataItem) {
        const { vendorCodeList = [] } = storeDropDataItem
        const transFormVendorCodeList = vendorCodeList.map(vendorCodeItem => {
          return {
            ...vendorCodeItem,
            id: vendorCodeItem.name
          }
        })
        vendorCodeDropData.push(...transFormVendorCodeList)
      }
    })
    return uniqBy(vendorCodeDropData, 'id')
  },
  // 异步获取 VendorCode DropData 关联 marketVal storeVal
  getVendorCodeDropDataPro(
    storeVal = [...this.marketStoreValueByVendor.store]
    , marketVal = [...this.marketStoreValueByVendor.market]
  ) {
    return new Promise((resolve) => {
      return this.getStoreDropDataPro('Vendor', marketVal)
        .then(storeDropData => {
          const vendorCodeDropData = this.getVendorCodeDropData(storeVal, null, storeDropData)
          resolve([...vendorCodeDropData])
        }).catch(err => {
          console.error('getVendorCodeDropDataPro error: ' + err)
          resolve([])
        })
    })
  },
  // 同步 根据vendorCodeDropData 获取 vendorCode 全选的值
  getVendorCodeCheckedAllValue(vendorCodeDropData = []) {
    const checkedAllValue = vendorCodeDropData.map(dropDataItem => {
      return dropDataItem.id
    })
    return checkedAllValue
  },
  // 根据vendorCodeDropData 与 当前vendorCodeValue对比获取下一步 vendorCodeValue
  getDiffVendorCodeValue(nextVendorCodeDropData = [], curVendorCodeValue = [...this.vendorCodeValue]) {
    const nextCheckAllVendorCodeValue = this.getVendorCodeCheckedAllValue(nextVendorCodeDropData)
    const diffIncludesCurValue = intersection(nextCheckAllVendorCodeValue, [...curVendorCodeValue])
    return diffIncludesCurValue
  },
  // 根据 storeVal 获取 vendorCode Val
  getVendorCodeValue(storeVal = [], vendorCodeDropData = [], curVendorCodeValue) {
    if (storeVal.length) {
      return this.getDiffVendorCodeValue(vendorCodeDropData, curVendorCodeValue)
    }
    return []
  },
  // 设置 VendorCode Value
  setVendorCodeValue(vendorCodeValue = []) {
    this.vendorCodeValue = [...vendorCodeValue]
  },
  // 设置 vendorCode Value 根据 vendor market store 变动
  setVendorCodeValueByBindMarketStore() {
    // 获取关联 vendorCodeDropData
    const nextVendorCodeValueDropData = this.getVendorCodeDropData(
      this.marketStoreValueByVendor.store
      , this.marketStoreValueByVendor.market
    )
    // 取两个 vendorCodeValue 的交集
    const diffIncludesCurValue = this.getDiffVendorCodeValue(isEmpty(this.marketStoreValueByVendor.store) ? [] : nextVendorCodeValueDropData)
    this.setVendorCodeValue(diffIncludesCurValue)
  },
  // 根据 store options 与 marketVal 获取store的value
  getStoreValueOfOptionsAndVal(options, value, mapKeyWithId = 'id') {
    if (!options) {
      throw new Error('Arg: options type must be a vaild Array')
    }
    if (!value?.length) {
      return []
    }
    return options.map(item => {
      return item[ mapKeyWithId ]
    })
  },
  // 重新初始化 MarketStore 的Options
  initMarketStoreDropDataPro() {
    return Promise.all([
      this.getMarketStorePro('Seller'),
      this.getMarketStorePro('Vendor')
    ])
  },
  getCategoryPro(designatedCommerceType, params) {
    return this.getProFunctionGenerator('category', designatedCommerceType, (data) => {
      return data.map(v => {
        if (!v?.children?.length) {
          v.children = null
        }
        return v
      })
    }, params)
  },
  getBrandPro(designatedCommerceType, params) {
    return this.getProFunctionGenerator('brand', designatedCommerceType, (data) => {
      return data.map(v => {
        if (!v?.children?.length) {
          v.children = null
        }
        v.id = v.id.toUpperCase()
        return v
      })
    }, params)
  },
  getOriginCategoryPro(designatedCommerceType, params, { formData } = {}) {
    return this.getProFunctionGenerator('originCategory', designatedCommerceType, (data) => {
      return data.map(v => {
        if (!v?.children?.length) {
          v.children = null
        }
        return v
      })
    }, params, formData)
  },
  getOriginBrandPro(designatedCommerceType, params, { formData } = {}) {
    return this.getProFunctionGenerator('originBrand', designatedCommerceType, (data) => {
      return data.map(v => {
        if (!v?.children?.length) {
          v.children = null
        }
        return v
      })
    }, params, formData)
  },
  getCustomBrandPro(params, { formData, designatedCommerceType } = {}, httpExtra = {}) {
    return this.getProFunctionGenerator('customBrand', designatedCommerceType, (data = []) => {
      return data
    }, params, formData, httpExtra)
  },
  getCustomCategoryPro(params, { formData, designatedCommerceType } = {}, httpExtra = {}) {
    return this.getProFunctionGenerator('customCategory', designatedCommerceType, (data = []) => {
      return data
    }, params, formData, httpExtra)
  },
  getProductTagPro(designatedCommerceType) {
    return this.getProFunctionGenerator('productTag', designatedCommerceType, (data) => {
      const tempData = data.map(v => {
        if (!v?.subTags?.length) {
          v.subTags = null
        }
        return v
      })
      localStorage.setItem('ProductTagIND', JSON.stringify(tempData))
      tempData.unshift(...DefaultTagData())
      return tempData
    }, null)
  },
  getTagOwnerPro() {
    return new Promise((resolve, reject) => {
      const cacheData = this.tagOwnerList || []
      if (cacheData.length) {
        resolve(cacheData)
        return
      }
      GetTagOwnerList()
        .then((data) => {
          data = data || []
          this.tagOwnerList = data
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // 根据 tagowner 获取 product
  getProductDropDataOfTagOwner(tagOwners) {
    return GetProductTagSellerDropDataByUserId({
      userIds: tagOwners
    }).then(res => {
      const tempData = res.map(v => {
        if (!v?.subTags?.length) {
          v.subTags = null
        }
        return v
      })
      localStorage.setItem('ProductTagIND', JSON.stringify(tempData))
      return Promise.resolve([...DefaultTagData(), ...res])
    }).catch(() => {
      return Promise.resolve([])
    })
  },
  getFinancesFormulaAction() {
    return new Promise((resolve, reject) => {
      if (this.checkedFinancesFormula.length) {
        resolve({
          checkList: this.checkedFinancesFormula,
          showTax: this.showTax
        })
        return
      }
      GetFinancesFormulaCheckedApi()
        .then((data) => {
          const { checkList, showTax } = data
          this.checkedFinancesFormula = checkList
          // 后端返回的showTax意义是show Tax in Sales & Expense，和页面开关状态相反
          // 也就是showTax为true时，税显示在各个模块中
          // 前端的showTax和开关状态相同，showTax为true时，税抽到单独分组中
          this.showTax = !showTax
          resolve({
            checkList,
            showTax: !showTax
          })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setCommerceType(val) {
    this.commerceType = val
  },
  setMarketStoreValue(val, commerceType, isAutoSetVendorCodeValue = true) {
    this.setMarketStoreValueMap(val, commerceType, isAutoSetVendorCodeValue)
  },
  getLowerCommerceType(commerceType) {
    return (commerceType || this.commerceType).toLowerCase()
  },
  setMarketStoreValueMap(val, commerceType, isAutoSetVendorCodeValue = true) {
    Object.assign(this.marketStoreValueMap[ this.getLowerCommerceType(commerceType) ], val)
    if (isAutoSetVendorCodeValue && this.getLowerCommerceType(commerceType) === 'vendor') {
      this.setVendorCodeValueByBindMarketStore(commerceType)
    }
  },
  getUserPermission() {
    return new Promise((resolve, reject) => {
      const cacheData = this.channelList || []
      if (cacheData.length) {
        if (this.isHideHeaderBar) {
          this.setCommerceHeaderBarVisible(true)
        }
        resolve(cacheData)
        return
      }
      GetUserPermissionApi()
        .then((data) => {
          const type = data.type || 0
          let temp = []
          const seller = [
            {
              name: $t('common.seller'),
              id: 'Seller'
            }
          ]
          const vendor = [
            {
              name: $t('common.vendor'),
              id: 'Vendor'
            }
          ]
          if (type === 1) {
            temp = vendor
            this.setCommerceType('Vendor')
          } else if (type === 3) {
            temp = seller
            this.setCommerceType('Seller')
          } else if (type === 2) {
            temp = vendor.concat(seller)
          }
          this.setChannelList(temp)
          resolve(temp)
        })
        .catch((err) => {
          reject(err)
        }).finally(() => {
          this.setCommerceHeaderBarVisible(!isEmpty(this.channelList))
        })
    })
  },
  setChannelList(list) {
    this.channelList = list
  },
  getMarketStoreInitValue() {
    return {
      seller: {
        market: [],
        store: []
      },
      vendor: {
        market: [],
        store: []
      }
    }
  },
  removeMarketStoreValue(commerceType = this.commerceType.toLowerCase()) {
    const MarketStoreInitValue = this.getMarketStoreInitValue()
    if (commerceType) {
      const currentTypeMarketStoreVal = this.marketStoreValueMap[commerceType]
      Object.assign(currentTypeMarketStoreVal, MarketStoreInitValue[commerceType])
      if (this.getLowerCommerceType(commerceType) === 'vendor') {
        // 初始化 vendorCodeValue
        this.setVendorCodeValue([])
      }
    } else {
      this.marketStoreValueMap = MarketStoreInitValue
      // 初始化 vendorCodeValue
      this.setVendorCodeValue([])
    }

    // session 操作 @Todo
  },
  clearStorageState(commerceType = this.commerceType.toLowerCase()) {
    this.removeMarketStoreValue(commerceType)
    if (commerceType === 'seller') {
      this.marketStore.Seller = []
      this.getMarketStorePro('Seller')
    } else if (commerceType === 'vendor') {
      this.marketStore.Vendor = []
      this.getMarketStorePro('Vendor')
    }
  },
  /**
   * 获取 Sns 数据的状态
   * @returns {Promise<void>}
   */
  async getVendorOrSellerIsSnsData({ withCancel = true } = {}) {
    const [snsData1p, snsData3p] = await Promise.allSettled([hasSnsApi({ withCancel }), hasSnsApi3p({ withCancel })])
    this.setHasSnsData(snsData1p.status === 'fulfilled' && snsData1p.value, snsData3p.status === 'fulfilled' && snsData3p.value)
  },
  setHasSnsData(vendor, seller) {
    this.hasSnsData = {
      vendor,
      seller
    }
  },
  /**
   * 获取 有 SnS 数据的 StoreId
   * @returns {Promise<void>}
   */
  async getVendorOrSellerSnsIds({ withCancel = true } = {}) {
    const [snsIds1p, snsIds3p] = await Promise.allSettled([SnSIdsApi({ withCancel }), SnSIdsApi3p({ withCancel })])
    this.setHasSnsIds(snsIds1p.status === 'fulfilled' ? snsIds1p.value?.map(item => `${item}`) : [], snsIds3p.status === 'fulfilled' ? snsIds3p.value : [])
  },
  setHasSnsIds(vendor, seller) {
    this.SnsIds = {
      vendor,
      seller
    }
  },
  setOverviewJumpHistoryMap(val, commerceType) {
    this.overviewJumpHistoryMap[ (commerceType || this.commerceType).toLowerCase() ] = val
  },
  // 判断当前CommerceType
  isCommerceType(commerceType = '') {
    return this.commerceType.toLowerCase() === commerceType.toLowerCase()
  },
  mountCustomHeader() {
    const frameStore = useFrameStore()
    const CommerceHeaderBar = defineAsyncComponent(() => import('@commerceCommon/components/CommerceHeaderBar/index.vue'))
    const HeaderBarSynchronization = defineAsyncComponent(() => import('@!/components/HeaderBarSynchronization.vue'))
    frameStore.setHeaderBar(defineComponent({
      render() {
        return h(CommerceHeaderBar, {}, {
          left: () => h(HeaderBarSynchronization)
        })
      }
    }))
  },
  setSalesHourlyType(val) {
    this.salesHourlyType = val
  },
  setSalePanelMap(val, commerceType) {
    Object.assign(this.salePanelMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  setRealTimeSalePanelMap(val, commerceType) {
    Object.assign(this.realTimeSalePanelMap[ (commerceType || this.commerceType).toLowerCase() ], val)
  },
  updateManagedByPacvue(status = ManagedByPacvueServerStatusMenu.FAILED) {
    this.managedByPacvue = status
  },
  /**
   * 获取是否由pacvue管理
   * @returns {Promise<void>}
   */
  async getManagedByPacvueImp({ withCancel = true } = {}) {
    let status = ManagedByPacvueServerStatusMenu.FAILED
    try {
      const data = await isManagedByPacvue({ withCancel })
      status = data ? ManagedByPacvueServerStatusMenu.YES : ManagedByPacvueServerStatusMenu.NO
    } catch {
      status = ManagedByPacvueServerStatusMenu.FAILED
    }
    this.updateManagedByPacvue(status)
  },
  getManualCasePermission() {
    return new Promise((resolve, reject) => {
      getManualPermission()
        .then((data) => {
          this.canManualCase = data
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  setIsAllAsyncDataComplete(val) {
    this.isAllAsyncDataComplete = val
  },
  setCommerceHeaderBarVisible(isVisible) {
    this.isHideHeaderBar = !isVisible
  },
  setDownloadTaskIdList(list) {
    this.downloadTaskIdList = list
  },
  addDownloadTaskId(uuid) {
    this.setDownloadTaskIdList([...this.downloadTaskIdList, uuid])
  },
  removeDownloadTaskId(uuid) {
    this.setDownloadTaskIdList(this.downloadTaskIdList.filter(item => item !== uuid))
  },
  clearDownloadTaskIdList() {
    this.setDownloadTaskIdList([])
  },
  setIsCoOpUser(value) {
    this.isCoOpUser = value
  }
}
