export const WatchFields = [
  'commerceType',
  // 'channelList',
  'marketStoreValueMap',
  'brandCategoryValueMap',
  'originBrandCategoryValueMap',
  'customBrandCategoryValueMap',
  'customBrandStore',
  'customCategoryStore',
  'overviewJumpHistoryMap',
  'distributorAndSellingMap',
  'tagOwnerValueMap',
  'tagValueMap',
  'repSuggestionType',
  'salesHourlyType',
  'problemTypeValueMap',
  'salePanelMap',
  'realTimeSalePanelMap',
  'vendorCodeValue',
  'downloadTaskIdList',
  'isCoOpUser'
]

/**
 * 字段是否需要压缩后再存储
 * @type {string[]}
 */
export const CompressFieldList = [
  // 'customCategoryStore'
]

export const CompressedPrefix = '__COMPRESSED__'
